function init() {
    new WOW().init();

    $('.cards').slick({
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              arrows: false,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
              dots: true,
              centerMode: true,
            }
          }
          // You can unslick at a given breakpoint now by adding:
          // settings: "unslick"
          // instead of a settings object
        ]
      });  
}

// window load binds 
window.onload = init;

function DOMLoaded() {
    // these are not always necessary but sometimes they fuck with ya
    if (helpers.iOS) {
        document.querySelector('html').classList.add('ios');
    } else if (helpers.IE()) {
        document.querySelector('html').classList.add('ie');
    }
}

// domcontent binds 
document.addEventListener('DOMContentLoaded', DOMLoaded);