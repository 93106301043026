//Function for changed header color when user selects another page.
$(document).ready(function () {

  var nomeField = $('#name');
  var emailField = $('#email');
  var telField = $('#tel');
  var assuntoField = $('#assunto');
  var msgField = $('#mensagem');
  var authorizarion01 = $('#msg-autorization01');


  var SPMaskBehavior = function (val) {
      return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
    },
    spOptions = {
      onKeyPress: function (val, e, field, options) {
        field.mask(SPMaskBehavior.apply({}, arguments), options);
      }
    };

  telField.mask(SPMaskBehavior, spOptions);


  nomeField.on('focusout', function () {
    validateName($(this));
  })

  emailField.on('focusout', function () {
    validateName($(this));
  })

  telField.on('focusout', function () {
    validatePhone($(this));
  })

  assuntoField.on('focusout', function () {
    validateName($(this));
  })

  msgField.on('focusout', function () {
    validateName($(this));
  })

  authorizarion01.on('focusout', function () {
    validateCheckbox($(this));
  })


  var contactForm = $("#form-contact");
  contactForm.on('submit', function (e) {
    e.preventDefault();
    var serializedData = contactForm.serialize();
    $("#submitContactForm").prop("disabled", true);

    if (!validate()) {
      $('.error-modal').fadeIn();
      $("#msgModalContato").html("Por favor verifique os campos e tente novamente.");
      $("#submitContactForm").prop("disabled", false);
      return false;
    }

    const ajax = $.ajax({
      url: "../sendEmail.php",
      method: 'post',
      dataType: "json",
      data: serializedData
    });

    ajax.done(function( res ) {
      if(res.err == 0){
        showSucessModal();
        $('#form-contact input').val('');
        $('#form-contact textarea').val('');
        $("#submitContactForm").prop("disabled", false);
        hcaptcha.reset();
      }
      else {
        $('.error-modal').fadeIn();
        $("#msgModalContato").html(res.msg);
        $("#submitContactForm").prop("disabled", false);
        hcaptcha.reset();
      }
    });

    ajax.fail(function(jqXHR, textStatus) {
      $('.error-modal').fadeIn();
      $("#msgModalContato").html("Erro ao enviar form, tente novamente.");
      $("#submitContactForm").prop("disabled", false);
      console.error(jqXHR);
      console.error(textStatus);
    });
    
  });

  var validate = function validate() {
    var valid = true;
    if (!validateName(nomeField)) {
      valid = false;
    }

    if (!validateEmail(emailField)) {
      valid = false;
    }

    if (!validatePhone(telField, true)) {
      valid = false;
    }

    if (!validateName(assuntoField)) {
      valid = false;
    }

    if (!validateName(msgField)) {
      valid = false;
    }

    if (!validateCheckbox(authorizarion01)) {
      valid = false;
    }
    return valid;
  };



  var validateName = function validateName(fld) {
    var valid = true;
    var fldval = fld.val().trim();
    //console.log('------------');
    //console.log('Validating Name');
    //console.log(fldval);

    fld.parent().removeClass('error success');
    if (fldval.length == "") {
      valid = false;
    }

    if (valid) {
      fld.parent().addClass('success');
    } else {
      fld.parent().addClass('error');
    }
    //console.log(valid);
    //console.log('------------');
    return valid;
  }

  var validateEmail = function validateEmail(fld, confirmation) {
    var valid = true;
    var fldval = fld.val().trim();
    //console.log('------------');
    //console.log('Validating E-mail');
    //console.log(fldval);

    fld.parent().removeClass('error success');
    if (fldval.length == 0) {
      valid = false;
    }
    if (!isValidEmail(fldval)) {
      valid = false;
    }

    if (confirmation) {
      if (fldval != $('#email').val().trim()) {
        valid = false;
      }
    } else {
      $('#reemail').blur();
    }

    if (valid) {
      fld.parent().addClass('success');
    } else {
      fld.parent().addClass('error');
    }
    //console.log(valid);
    //console.log('------------');
    return valid;
  };

  var validatePhone = function validatePhone(fld, req) {
    var valid = true;
    var fldval = fld.val().trim();
    //console.log('------------');
    //console.log('Validating Phone Number');
    //console.log(fldval);

    fld.parent().removeClass('error success');
    if (req) {
      if (fldval.length < 14) {
        valid = false;
      }
    } else {
      if (fldval.length > 0) {
        req = true;
        if (fldval.length < 14) {
          valid = false;
        }
      }
    }

    if (req) {
      if (valid) {
        fld.parent().addClass('success');
      } else {
        fld.parent().addClass('error');
      }
    }
    //console.log(valid);
    //console.log('------------');
    return valid;
  }


  var validateCheckbox = function validateCheckbo01(fld) {
    var valid = true;
    var fldval = fld.val().trim();
    //console.log('------------');
    //console.log('Validating Checkbox01');
    //console.log(fldval);

    fld.parent().removeClass('error success');
    if (fld.is(":checked")) {
      valid = true;
    } else {
      valid = false;
    }

    if (valid) {
      fld.parent().addClass('success');
    } else {
      fld.parent().addClass('error');
    }
    //console.log(valid);
    //console.log('------------');
    return valid;
  }


  var showSucessModal = function () {
    $('.success-modal').fadeIn();
  }

  var closeSucessModal = function () {
    $('.success-modal').fadeOut();
    $('.error-modal').fadeOut();
    $('.popup').fadeOut();
  }

  $('.close-modal-trigger').on('click', function () {
    closeSucessModal();
  })

  function isValidEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }



  var loc = window.location.href; // returns the full URL
  var rootHomolog = "http://phpstack-427964-2234789.cloudwaysapps.com/";
  var endHomolog = loc.slice(loc.lastIndexOf(rootHomolog) + rootHomolog.length);
  var rootProd = "https://www.hospitaljacarepagua.com.br/";
  var endProd = loc.slice(loc.lastIndexOf(rootProd) + rootProd.length);

  //Remove All Other Classes
  $('#close-menu-mobile a').removeClass('header-selected');

  if (endHomolog.length <= 0 || endProd.length <= 0) { // If Empty or if just home URL
    $('#header-home-page').addClass('header-selected');
  }
  if (endHomolog === "quem-somos/" || endHomolog === "quem-somos" || rootProd === "quem-somos/" || rootProd === "quem-somos") { // if page = Sobre
    $('#header-sobre').addClass('header-selected');
  }

  if (endHomolog === "convenios/" || endHomolog === "convenios" || endProd === "convenios/" || endProd === "convenios") { // if page = Equipe
    $('#header-equipe').addClass('header-selected');
  }

  if (endHomolog === "contato/" || endHomolog === "contato" || endProd === "contato/" || endProd === "contato") { // if page = contato
    $('#header-contato').addClass('header-selected');
  }

  if (endHomolog === "servicos/estrutura/" || endHomolog === "servicos/estrutura" || endProd === "servicos/estrutura/" || endProd === "servicos/estrutura") { // if page = contato
    $('#header-service').addClass('header-selected');
  }

  if (endHomolog === "servicos/pronto-socorro/" || endHomolog === "servicos/pronto-socorro" || endProd === "servicos/pronto-socorro/" || endProd === "servicos/pronto-socorro") { // if page = contato
    $('#header-service').addClass('header-selected');
  }

  if (endHomolog === "servicos/especialidades/" || endHomolog === "servicos/especialidades" || endProd === "servicos/especialidades/" || endProd === "servicos/especialidades") { // if page = contato
    $('#header-service').addClass('header-selected');
  }

  if (endHomolog === "noticias-e-imprensa/" || endHomolog === "noticias-e-imprensa" || endProd === "noticias-e-imprensa/" || endProd === "noticias-e-imprensa") { // if page = contato
    $('#header-imprensa').addClass('header-selected');
  }
});