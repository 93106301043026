const btnMobile = document.getElementById('btn-menu');
const btnMobileService = document.getElementById('header-service');
// const clickOpc = document.getElementById('close-menu-mobile');

function toggleMenu(){
    const nav = document.getElementById('main-nav');

    if(window.matchMedia("(max-width:1278px)").matches){
        nav.classList.toggle('active');
        btnMobileService.classList.remove('openmenu');
    }
}

function toggleMenuService(){
    const nav = document.getElementById('header-service');

        nav.classList.toggle('openmenu');
}

btnMobile.addEventListener('click', toggleMenu);
btnMobileService.addEventListener('click', toggleMenuService);
// clickOpc.addEventListener('click', toggleMenu);




